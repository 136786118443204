import React from "react";
import './ReviewUs.css'
import { FaYelp, FaFacebookF, FaGooglePlusG } from "react-icons/fa";
const ReviewUs = () => {
  return (
    <>
      <div className="ReviewUsCont">
        <h2>Please Review Us</h2>
        <div className="ReviewSocialIcon">
          <span>
            <a href="https://www.yelp.com/writeareview/biz/JEBjQpEABtx-F6pHfaC5AA?return_url=%2Fbiz%2FJEBjQpEABtx-F6pHfaC5AA&source=biz_details_war_button" target="_blank" rel="noopener noreferrer">
              <FaYelp />
            </a>
          </span>
          <span>
            <a href="https://www.facebook.com/royaltycutzseattle" target="_blank" rel="noopener noreferrer">
              <FaFacebookF />
            </a>
          </span>
          <span>
            <a href="https://www.google.com/search?q=royalty%20cutz%20seattle&oq=royalty+cutz+seattle&aqs=chrome..69i57.4767j0j7&sourceid=chrome&ie=UTF-8&npsic=0&rflfq=1&rlha=0&rllag=47605055,-122345460,8916&tbm=lcl&rldimm=4143086805328851041&ved=2ahUKEwj5pve729TdAhXyOn0KHYvECT4QvS4wAHoECAMQIQ&rldoc=1&tbs=lrf:!2m1!1e2!2m1!1e3!3sIAE,lf:1,lf_ui:2#lrd=0x549015bd3b012931:0x397f337f993bf061,3,,,&rlfi=hd:;si:4143086805328851041;mv:!3m12!1m3!1d87203.55562011017!2d-122.3454606!3d47.6050552!2m3!1f0!2f0!3f0!3m2!1i5!2i389!4f13.1;tbs:lrf:!2m1!1e2!2m1!1e3!3sIAE,lf:1,lf_ui:2" target="_blank" rel="noopener noreferrer">
              <FaGooglePlusG />
            </a>
          </span>
        </div>
      </div>
    </>
  )
}
export default ReviewUs;