import './App.css';
import { Routes, Route } from 'react-router-dom';
import Header from './Components/Shared/Header/Header';
import Contact from './Components/Others/Contact/Contact';
import MainPage from './Pages/MainPage';
import PrivacyPolicy from './Components/Others/PrivacyPolicy/PrivacyPolicy';
import SmsPolicy from './Components/Others/SmsPolicy/SmsPolicy';
import TermsOfService from './Components/Others/TermsOfService/TermsOfService';

function App() {
  return (
    <div className="App">
      <Header />
      <Routes>
        <Route path="/" element={<MainPage />} />
        <Route path="/privacy" element={<PrivacyPolicy />} />
        <Route path="/sms" element={<SmsPolicy />} />
        <Route path="/terms" element={<TermsOfService />} />
      </Routes>
      <Contact />
    </div>
  );
}

export default App;