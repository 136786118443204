// src/Components/Others/SmsPolicy/SmsPolicy.jsx
import React from 'react';
import './SmsPolicy.css';

const SmsPolicy = () => {
    return (
        <div className="PolicyCont">
            <h1>SMS Policy</h1>
            <p>
                <strong>Terms and Conditions for SMS Communication</strong><br />
                By opting in to receive SMS communications from Royalty Cutz Barber School ("we," "us," or "our"), you agree to the following Terms and Conditions:
            </p>
            <ul>
                <li><strong>Consent to Receive SMS:</strong> By providing your mobile phone number, you consent to receive SMS messages from Royalty Cutz Barber School regarding your appointments, updates, and any additional help we can provide. Message frequency may vary based on your interactions with us.</li>
                <li><strong>Message and Data Rates:</strong> Standard message and data rates may apply depending on your mobile carrier and plan. You are responsible for any charges incurred for receiving SMS messages.</li>
                <li><strong>Opting Out:</strong> You may opt out of receiving SMS messages at any time by replying with "STOP" to any SMS message you receive from us. After opting out, you will receive a confirmation message, and we will cease sending SMS messages to your number. Please note that opting out may affect your ability to receive certain types of communication.</li>
                <li><strong>Help and Support:</strong> If you need assistance or have questions about our SMS service, reply with "HELP" to any SMS message you receive or contact our customer support team at (206) 523-1250.</li>
                <li><strong>Privacy:</strong> Your mobile phone number and other personal information will be handled in accordance with our Privacy Policy. We do not sell or share your phone number with third parties except as required by law.</li>
                <li><strong>Changes to Terms:</strong> We may update these terms and conditions from time to time. Any changes will be effective when we send a new SMS message or update our terms on our website. Your continued use of the SMS service constitutes acceptance of the revised terms.</li>
                <li><strong>Termination:</strong> We reserve the right to terminate or modify the SMS service at any time without prior notice.</li>
                <li><strong>Liability:</strong> Royalty Cutz Barber School is not responsible for any delays or failures in the delivery of SMS messages due to issues with your mobile carrier or other technical problems.</li>
            </ul>
            <p>
                By opting in, you acknowledge that you have read, understood, and agree to these terms and conditions.
            </p>
            <p>
                © All rights reserved. September, 2024
            </p>
        </div>
    );
};

export default SmsPolicy;