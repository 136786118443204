// src/Components/Others/TermsOfService/TermsOfService.jsx
import React from 'react';
import './TermsOfService.css';

const TermsOfService = () => {
    return (
        <div className="PolicyCont">
            <h1>Terms of Service</h1>
            <p>
                <strong>1. Acceptance of Terms</strong><br />
                By accessing and using the Royalty Cutz Barber School website (the "Site"), you agree to comply with and be bound by these Terms of Service (the "Terms"). If you do not agree to these Terms, please refrain from using the Site. Royalty Cutz Barber School reserves the right to modify these Terms at any time, and such modifications will be effective immediately upon posting on the Site. Your continued use of the Site will signify your acceptance of any revised Terms.
            </p>
            <p>
                <strong>2. Use of the Site</strong><br />
                The content on this Site, including but not limited to text, images, and videos, is provided for informational purposes about Royalty Cutz Barber School. You may use the Site for personal, non-commercial purposes only. Unauthorized use of any material or content from the Site may violate copyright, trademark, and other laws.
            </p>
            <ul>
                <li>Not use the Site for any unlawful purpose.</li>
                <li>Not interfere with or disrupt the functionality of the Site or its servers.</li>
                <li>Not reproduce, duplicate, copy, sell, or exploit any portion of the Site without express written permission from Royalty Cutz Barber School.</li>
            </ul>
            <p>
                <strong>3. Intellectual Property</strong><br />
                All content and materials provided on the Site, including logos, branding, and multimedia, are the intellectual property of Royalty Cutz Barber School or its content creators. You may not reproduce, distribute, modify, or create derivative works from any materials without prior written consent.
            </p>
            <p>
                <strong>4. Privacy Policy</strong><br />
                Your use of the Site is governed by our Privacy Policy, which outlines how we collect, use, and protect your personal information. Please review our Privacy Policy carefully.
            </p>
            <p>
                <strong>5. Third-Party Links</strong><br />
                The Site may contain links to third-party websites or services that are not owned or controlled by Royalty Cutz Barber School. We are not responsible for the content, privacy policies, or practices of any third-party websites. You acknowledge and agree that Royalty Cutz Barber School will not be liable for any damage or loss caused by your use of any third-party websites or services.
            </p>
            <p>
                <strong>6. Disclaimers and Limitation of Liability</strong><br />
                The Site and its content are provided "as is" and without warranties of any kind, either express or implied. Royalty Cutz Barber School does not warrant that the Site will be error-free, secure, or uninterrupted. To the fullest extent permitted by law, Royalty Cutz Barber School disclaims any liability for any direct, indirect, incidental, consequential, or punitive damages arising from your use of the Site.
            </p>
            <p>
                <strong>7. Indemnification</strong><br />
                You agree to indemnify, defend, and hold harmless Royalty Cutz Barber School, its officers, directors, employees, and affiliates from any claims, liabilities, damages, losses, or expenses arising from your violation of these Terms or your use of the Site.
            </p>
            <p>
                <strong>8. Governing Law</strong><br />
                These Terms shall be governed by and construed in accordance with the laws of the state in which Royalty Cutz Barber School operates, without regard to conflict of law principles. Any legal action or proceeding arising under these Terms will be brought exclusively in the state or federal courts located in the jurisdiction where Royalty Cutz Barber School is headquartered.
            </p>
            <p>
                <strong>9. Contact Information</strong><br />
                If you have any questions about these Terms, please contact us at:
            </p>
            <p>
                Royalty Cutz Barber School<br />
                7712 Aurora Ave N, Seattle, WA 98103<br />
                (206) 523-1250
            </p>
        </div>
    );
};

export default TermsOfService;