import React from "react";
import Gallery from "../Components/Others/Gallery/Gallery";
import ReviewUs from "../Components/Others/ReviewUs/ReviewUs";
const MainPage = () => {
  return (
    <>
      <Gallery />
      <ReviewUs />
    </>
  )
}
export default MainPage;