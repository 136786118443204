import React from 'react';
import './Contact.css';
import RoyalBook from '../../../Assets/Images/RoyalBook.png';
import { FaPhoneAlt } from 'react-icons/fa';

const Contact = () => {
  return (
    <>
      <div className="ContactCont">
        <h4>
          Call us or book through <img className="RoyalImg" src={RoyalBook} alt="RoyalBook" /> app to
          schedule an appointment
        </h4>
        <div className="CallBook">
          <span>
            <a href="tel:+1-206-5231250" target="_blank" rel="noopener noreferrer">
              <FaPhoneAlt />
            </a>
          </span>
          <button className="BookNowBtn" onClick={() => window.open('https://royalbookapp.com/', '_blank')}>Book Now</button>
        </div>
        <h5 className="Appoint">Walk-ins Welcome | Appointment Recommended</h5>
        <h5 className="Address">7712 Aurora Ave N. Seattle, WA 98103</h5>
        <div className="TimingNMap">
          <div>
            <h2>Hours</h2>
            <div className="WeeeklyHours">
              <span>
                <strong>Sunday:</strong>
                <p>10:00am - 8:00pm</p>
              </span>
              <span>
                <strong>Monday:</strong>
                <p>10:00am - 8:00pm</p>
              </span>
              <span>
                <strong>Tuesday:</strong>
                <p>10:00am - 8:00pm</p>
              </span>
              <span>
                <strong>Wednesday:</strong>
                <p>10:00am - 8:00pm</p>
              </span>
              <span>
                <strong>Thursday:</strong>
                <p>10:00am - 8:00pm</p>
              </span>
              <span>
                <strong>Friday:</strong>
                <p>10:00am - 8:00pm</p>
              </span>
              <span>
                <strong>Saturday:</strong>
                <p>10:00am - 8:00pm</p>
              </span>
            </div>
          </div>
          <div className="GoogleMap" style={{ position: "relative" }}>
            <iframe
              title="Royalty Cutz Location"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2689.521128437314!2d-122.34646968453678!3d47.68541!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x5490149c8a4f4e65%3A0x3d4a832e8eabc12c!2s7712%20Aurora%20Ave%20N%2C%20Seattle%2C%20WA%2098103%2C%20USA!5e0!3m2!1sen!2sus!4v1234567890123!5m2!1sen!2sus"
              style={{
                border: 0,
                width: "100%",
                height: "300px",
                borderRadius: "8px",
                boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
              }}
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
            {/* Tooltip */}
            <div
              style={{
                position: "absolute",
                top: "25%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                backgroundColor: "white",
                color: "black",
                padding: "10px",
                fontFamily: "Raleway",
                borderRadius: "8px",
                textAlign: "center",
                cursor: "pointer",
                zIndex: 10,
              }}
              onClick={() => window.open("https://www.google.com/maps/dir/?api=1&destination=47.68541,-122.34428", "_blank")}
            >
              <strong>Royalty Cutz</strong>
              <br />
              <a
                href="https://www.google.com/maps/dir/?api=1&destination=47.68541,-122.34428"
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: "#00aaff", textDecoration: "underline" }}
              >Directions
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className='CopyRight'>
        ©{new Date().getFullYear()} by Royalty Cutz
      </div>
    </>
  );
};

export default Contact;
