import React, { useState } from "react";
import BrandLogo from '../../../Assets/Images/RoyaltyCutzLogo.png';
import { Link } from "react-router-dom";
import { FaFacebookF, FaInstagram, FaMapMarkerAlt, FaBars, FaTimes } from "react-icons/fa";
import './Header.css';

const Header = () => {
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);

  const handleLinkClick = () => {
    setDropdownVisible(false);
    setMenuOpen(false);
    window.reload();
  };

  return (
    <>
      <div className="HeaderCont">
        <Link to="/" onClick={handleLinkClick}> <img className="BrandLogo" src={BrandLogo} alt="Brand Logo" /></Link>
        <h1 className="BrandName">Royalty Cutz</h1>
        <hr className="HrLine HrMob" />

        <div style={{ textAlign: 'center' }}>
          <div className="SocialIcon">
            <a href="https://www.facebook.com/royaltycutzseattle" target="_blank" rel="noopener noreferrer">
              <FaFacebookF />
            </a>
            <a href="https://www.instagram.com/royalty.cutz/" target="_blank" rel="noopener noreferrer">
              <FaInstagram />
            </a>
            <a href="https://www.google.com/maps/place/Royalty+Cutz+Barbers" target="_blank" rel="noopener noreferrer">
              <FaMapMarkerAlt />
            </a>
          </div>
          <button
            className="BookBtn"
            onClick={() => window.open('https://royalbookapp.com/', '_blank')}
          >
            Book Now
          </button>
        </div>
      </div>
      <hr className="HrLine HrDesktop" />

      {/* More Dropdown (visible on desktop) */}
      <div
        className="MoreDropdownContainer DesktopOnly"
        onMouseEnter={() => setDropdownVisible(true)}
        onMouseLeave={() => setDropdownVisible(false)}
      >
        <span className="MoreText">More</span>
        {dropdownVisible && (
          <div className="DropdownMenu">
            <Link to="/terms" className="DropdownItem" onClick={handleLinkClick}>Terms of Service</Link>
            <Link to="/privacy" className="DropdownItem" onClick={handleLinkClick}>Privacy Policy</Link>
            <Link to="/sms" className="DropdownItem" onClick={handleLinkClick}>Terms of SMS</Link>
          </div>
        )}
      </div>

      {/* Hamburger Menu (visible on mobile) */}
      <div className="HamburgerIcon MobileOnly" onClick={() => setMenuOpen(true)}>
        <FaBars />
      </div>

      {/* Full-Screen Menu (visible on mobile) */}
      {menuOpen && (
        <div className="MobileMenuOverlay">
          <div className="CloseIcon" onClick={() => setMenuOpen(false)}>
            <FaTimes />
          </div>
          <div className="MobileMenuLinks">
            <Link to="/terms" className="DropdownItemMob" onClick={handleLinkClick}>Terms of Service</Link>
            <Link to="/privacy" className="DropdownItemMob" onClick={handleLinkClick}>Privacy Policy</Link>
            <Link to="/sms" className="DropdownItemMob" onClick={handleLinkClick}>Terms of SMS</Link>
          </div>
        </div>
      )}
    </>
  );
};

export default Header;
