// src/Components/Others/PrivacyPolicy/PrivacyPolicy.jsx
import React from 'react';
import './PrivacyPolicy.css';

const PrivacyPolicy = () => {
    return (
        <div className="PolicyCont">
            <h1>Privacy Policy</h1>
            <p>
                At Royalty Cutz Barber School ("we," "our," "us"), your privacy is important to us. This Privacy Policy outlines how we collect, use, and protect your personal information when you visit or interact with our website (the "Site"). By using our Site, you agree to the collection and use of information in accordance with this policy.
            </p>
            <p>
                <strong>1. Information We Collect</strong><br />
                We may collect personal information from you when you visit our Site or interact with its content. The types of information we collect include:
            </p>
            <ul>
                <li><strong>Personal Identification Information:</strong> This may include your name, email address, phone number, or other details that you voluntarily provide via forms or other means on the Site.</li>
                <li><strong>Non-Personal Identification Information:</strong> We may collect non-personal information such as browser type, IP address, referring website, and other technical information related to your interaction with the Site.</li>
            </ul>
            <p>
                <strong>2. How We Use Your Information</strong><br />
                The personal information we collect may be used in the following ways:
            </p>
            <ul>
                <li>To respond to inquiries or requests made through the Site.</li>
                <li>To improve the content and functionality of the Site.</li>
                <li>To communicate with you, if necessary, regarding updates, events, or other news about Royalty Cutz Barber School.</li>
            </ul>
            <p>
                <strong>3. How We Protect Your Information</strong><br />
                We implement a variety of security measures to maintain the safety of your personal information. However, no method of transmission over the internet or method of electronic storage is 100% secure. While we strive to use commercially acceptable means to protect your personal information, we cannot guarantee its absolute security.
            </p>
            <p>
                <strong>4. Sharing of Information</strong><br />
                We do not sell, trade, or otherwise transfer your personal information to outside parties unless required by law or necessary to protect our rights, comply with a judicial proceeding, court order, or legal process.
            </p>
            <p>
                However, we may share non-personal identification information with trusted third parties who assist us in operating our website or conducting our business, as long as those parties agree to keep this information confidential.
            </p>
            <p>
                <strong>5. Third-Party Links</strong><br />
                Our Site may contain links to third-party websites that are not operated or controlled by Royalty Cutz Barber School. We are not responsible for the content, privacy practices, or the handling of your information by these third-party websites. We encourage you to review the privacy policies of any third-party websites before providing any personal information.
            </p>
            <p>
                <strong>6. Use of Cookies</strong><br />
                The Site may use "cookies" to enhance your user experience. Cookies are small data files that are placed on your device when you visit a website. These cookies may track your activity on the Site and help us understand how users engage with our content. You may choose to disable cookies through your browser settings, but doing so may limit some features of the Site.
            </p>
            <p>
                <strong>7. Your Rights</strong><br />
                You have the right to request access to, correction of, or deletion of your personal information that we have collected. To make such a request, please contact us using the information provided below. We will respond to your request in compliance with applicable laws.
            </p>
            <p>
                <strong>8. Changes to this Privacy Policy</strong><br />
                We reserve the right to update or modify this Privacy Policy at any time. Any changes to this policy will be posted on this page, and the "Effective Date" at the top will be updated accordingly. Your continued use of the Site following any changes constitutes your acceptance of the updated Privacy Policy.
            </p>
            <p>
                <strong>9. Contact Us</strong><br />
                If you have any questions or concerns regarding this Privacy Policy, please contact us at:
            </p>
            <p>
                Royalty Cutz Barber School<br />
                7712 Aurora Ave N, Seattle, WA 98103<br />
                (206) 523-1250
            </p>
        </div>
    );
};

export default PrivacyPolicy;